import { CartService } from '@vue-storefront/core/data-resolver'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { formatShippingAddress } from '@vue-storefront/core/helpers/adress';
const couponActions = {
  async removeCoupon ({ getters, dispatch, commit, rootGetters }, { sync = true, shouldAskForShippingMethods = false } = {}) {
    if (getters.canSyncTotals) {
      const shippingDetails = rootGetters['checkout/getShippingDetails']
      const personalDetails = rootGetters['checkout/getPersonalDetails']

      const storeView = currentStoreView()
      const address = shouldAskForShippingMethods ? formatShippingAddress(shippingDetails, storeView)
        : { }
      const { result, code } = await CartService.removeCoupon({
        ...address,
        email: personalDetails.emailAddress
      })
      if (code === 404) {
        return result
      }
      const totals = result.totals
      if (totals) {
        const totalsPayload = {
          response: {
            result: { totals },
            resultCode: 200
          }
        }
        dispatch('overrideServerTotals', totalsPayload)
        commit(types.CART_UPD_TOTALS, {
          itemsAfterTotal: totals.items,
          totals,
          platformTotalSegments: totals.total_segments
        })
        commit(types.CART_SET_TOTALS_SYNC)
        commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
      }
    }
  },
  async applyCoupon ({ getters, dispatch, commit, rootGetters }, { couponCode, shouldAskForShippingMethods }) {
    if (couponCode && getters.canSyncTotals) {
      const shippingDetails = rootGetters['checkout/getShippingDetails']
      const personalDetails = rootGetters['checkout/getPersonalDetails']

      const storeView = currentStoreView()
      const address = shouldAskForShippingMethods ? formatShippingAddress(shippingDetails, storeView)
        : { }

      const { result, code } = await CartService.applyCoupon(couponCode, {
        ...address,
        email: personalDetails.emailAddress
      })
      if (code === 404) {
        return result
      }
      const totals = result.totals
      if (totals) {
        const totalsPayload = {
          response: {
            result: { totals },
            resultCode: 200
          }
        }
        dispatch('overrideServerTotals', totalsPayload)
        commit(types.CART_UPD_TOTALS, {
          itemsAfterTotal: totals.items,
          totals,
          platformTotalSegments: totals.total_segments
        })
        commit(types.CART_SET_TOTALS_SYNC)
        commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
      }
    }
  }
}

export default couponActions
